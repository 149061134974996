import { TemplateType } from 'types/templates.type';
import { AssetManagementConfig } from 'components/asset-management/types/asset-management-config.type';
import staticAssetZipImage from 'components/creative-builder/CreativeBuilderTemplateDialog/images/zip.png';
import staticAssetImgImage from 'components/creative-builder/CreativeBuilderTemplateDialog/images/image.png';
import staticAssetVideoImage from 'components/creative-builder/CreativeBuilderTemplateDialog/images/video.png';
import staticAssetAudioImage from 'components/creative-builder/CreativeBuilderTemplateDialog/images/audio.png';
import Translation from 'components/data/Translation';
import { TEMPLATE_MANAGEMENT_DEFAULT_SETUP } from './default-setup';
import { StaticAssets, StaticAssetType } from '../types/template-management.type';

const TEMPLATE_MANAGEMENT_CONFIG: AssetManagementConfig = {
    storeName: 'TemplateManagement',
    type: 'template',
    gridType: 'grid',
    dropZone: false,
    setupResource: 'setup_templateManagement',
    assetsPlaceholder: 'media',
    assetsListViewHeaderKeys: ['empty', 'title', 'type', 'content', 'lastModified', 'empty'],
    subtypeIcons: {
        displayAdDesigned: 'web_outlined',
        dynamicVideoDesigned: 'video_gallery',
        dynamicImageDesigned: 'image_outlined',
        dynamicPDFDesigned: 'picture_as_pdf_outlined',
        dynamicAfterEffects: 'movie_filter_outlined',
        dynamicInDesign: 'description_outlined',
        displayAd: 'web_outlined',
        dynamicImage: 'image_outlined',
        dynamicVideo: 'video_gallery',
        dynamicPDF: 'picture_as_pdf_outlined',
        emailBase: 'description',
        emailBlock: 'description',
        landingpageBlock: 'description',
        marketDefault: 'description',
        social: 'description',
        socialBase: 'description',
        socialChannelItem: 'description',
        staticAsset: 'description',
        assetItemOverwrites: 'folder_zip',
        socialChannelItemOverwrites: 'folder_zip'
    }
};

const CREATIVE_TEMPLATE_TYPES: TemplateType[] = [
    'dynamicImage',
    'dynamicImageDesigned',
    'dynamicVideo',
    'dynamicVideoDesigned',
    'dynamicPDF',
    'dynamicPDFDesigned',
    'displayAd',
    'displayAdDesigned',
    'dynamicInDesign',
    'dynamicAfterEffects',
    'staticAsset',
    'socialChannelItem'
];

const CUSTOM_TEMPLATE_TYPES: TemplateType[] = [
    'dynamicVideo',
    'dynamicImage',
    'displayAd',
    'dynamicPDF',
    'socialChannelItemOverwrites',
    'assetItemOverwrites',
    'marketDefault',
    'emailBase',
    'emailBlock',
    'landingpageBlock',
    'social',
    'socialBase',
    'socialChannelItem',
    'staticAsset'
];

const EMAIL_TEMPLATE_TYPES: TemplateType[] = ['emailBase', 'emailBlock'];

const TD_TEMPLATE_TYPES: TemplateType[] = [
    'dynamicImageDesigned',
    'dynamicVideoDesigned',
    'displayAdDesigned',
    'dynamicPDFDesigned',
    'dynamicAfterEffects',
    'dynamicInDesign'
];

const STATIC_ASSETS_V1 = {
    staticAssetDisplay: {
        title: 'HTML5 Ad (set) upload',
        description: 'Upload a pre-existing set of display ads as a zip.',
        identifier: 'static_asset_display',
        type: 'staticAssetDisplay',
        image: staticAssetZipImage
    },
    staticAssetVideo: {
        title: 'Video Upload',
        description: 'Upload a pre-existing set of videos.',
        identifier: 'static_asset_video',
        type: 'staticAssetVideo',
        image: staticAssetVideoImage
    },
    staticAssetImage: {
        title: 'Image (set) upload',
        description: 'Upload a pre-existing set of images.',
        identifier: 'static_asset_image',
        type: 'staticAssetImage',
        image: staticAssetImgImage
    },
    staticAssetPDF: {
        title: 'PDF (set) upload',
        description: 'Upload a pre-existing set of PDFs.',
        identifier: 'static_asset_pdf',
        type: 'staticAssetPDF',
        image: staticAssetImgImage
    },
    staticAssetAudio: {
        title: 'Audio Upload',
        description: 'Upload a pre-existing set of audio files.',
        identifier: 'static_asset_audio',
        type: 'staticAssetAudio',
        image: staticAssetAudioImage
    }
};

const STATIC_ASSETS: StaticAssets = {
    staticAssetDisplay: {
        title: Translation.get('selector.static_assets.display.title', 'template-management'),
        type: 'template',
        subType: 'staticAsset',
        data: {
            description: Translation.get('selector.static_assets.display.desc', 'template-management'),
            identifier: 'static_asset_display',
            icon: 'static-asset-display'
        }
    },
    staticAssetVideo: {
        title: Translation.get('selector.static_assets.video.title', 'template-management'),
        type: 'template',
        subType: 'staticAsset',
        data: {
            description: Translation.get('selector.static_assets.video.desc', 'template-management'),
            identifier: 'static_asset_video',
            icon: 'static-asset-video'
        }
    },
    staticAssetImage: {
        title: Translation.get('selector.static_assets.image.title', 'template-management'),
        type: 'template',
        subType: 'staticAsset',
        data: {
            description: Translation.get('selector.static_assets.image.desc', 'template-management'),
            identifier: 'static_asset_image',
            icon: 'static-asset-image'
        }
    },
    staticAssetPDF: {
        title: Translation.get('selector.static_assets.pdf.title', 'template-management'),
        type: 'template',
        subType: 'staticAsset',
        data: {
            description: Translation.get('selector.static_assets.pdf.desc', 'template-management'),
            identifier: 'static_asset_pdf',
            icon: 'static-asset-pdf'
        }
    },
    staticAssetAudio: {
        title: Translation.get('selector.static_assets.audio.title', 'template-management'),
        type: 'template',
        subType: 'staticAsset',
        data: {
            description: Translation.get('selector.static_assets.audio.desc', 'template-management'),
            identifier: 'static_asset_audio',
            icon: 'static-asset-audio'
        }
    }
};

const STATIC_ASSET_TYPES: StaticAssetType[] = ['staticAssetDisplay', 'staticAssetVideo', 'staticAssetImage', 'staticAssetPDF', 'staticAssetAudio'];

export {
    TEMPLATE_MANAGEMENT_CONFIG,
    TEMPLATE_MANAGEMENT_DEFAULT_SETUP,
    CREATIVE_TEMPLATE_TYPES,
    CUSTOM_TEMPLATE_TYPES,
    TD_TEMPLATE_TYPES,
    STATIC_ASSETS_V1,
    STATIC_ASSETS,
    STATIC_ASSET_TYPES,
    EMAIL_TEMPLATE_TYPES
};
