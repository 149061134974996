import React from 'react';
import GridWrapper from 'components/ui-components/GridWrapper';
import { SelectorStaticAsset } from 'components/asset-management/components/selector/types/selector-asset.type';
import GenericIcon from 'components/ui-components/GenericIcon';
import { StaticAsset, StaticAssetType } from 'components/template-management/types/template-management.type';
import { STATIC_ASSETS } from 'components/template-management/constants';
import { TemplateManagementSelectorStaticAssetTile } from '../';

import '../styles/static-assets.scss';

interface Props {
    staticAssetType: StaticAssetType[];
    onSelectItem: (item: StaticAsset) => void;
}

const TemplateManagementSelectorStaticAssets = ({ staticAssetType, onSelectItem }: Props) => {
    const selectorItems: SelectorStaticAsset[] = staticAssetType.map((s) => ({
        id: s,
        title: STATIC_ASSETS[s].title,
        subtitle: STATIC_ASSETS[s].data.description,
        icon: <GenericIcon icon={STATIC_ASSETS[s].data.icon} />,
        originalRecord: STATIC_ASSETS[s]
    }));
    return (
        <div className="template-management-selector-static-assets">
            <GridWrapper targetSize={220}>
                {selectorItems.map((item) => (
                    <TemplateManagementSelectorStaticAssetTile key={item.id} item={item} onSelect={() => onSelectItem(item.originalRecord)} />
                ))}
            </GridWrapper>
        </div>
    );
};

export default TemplateManagementSelectorStaticAssets;
