import React from 'react';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';

import '../styles/info.scss';

interface Props {
    title: string;
    subtitle: string;
    small?: boolean;
    icon?: string;
}

const TemplateManagementAssetTileInfo = ({ title, subtitle, icon = 'design_services', small }: Props) => {
    return (
        <div className="template-management-asset-tile-info">
            <Icon baseClassName="material-icons material-icons-outlined" className="template-management-asset-tile-info__icon">
                {icon}
            </Icon>
            <div className="template-management-asset-tile-info__text">
                <div className={classNames('template-management-asset-tile-info__title', { 'template-management-asset-tile-info__title--small': small })}>
                    {title}
                </div>
                <div className="template-management-asset-tile-info__subtitle">{subtitle}</div>
            </div>
        </div>
    );
};

export default TemplateManagementAssetTileInfo;
