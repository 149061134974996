import React from 'react';
import Translation from 'components/data/Translation';
import { StaticAsset } from 'components/template-management/types/template-management.type';
import Button from 'components/ui-components-v2/Button';
import { SelectorStaticAsset } from 'components/asset-management/components/selector/types/selector-asset.type';
import { TemplateManagementAssetTileInfo } from '../../asset-tile';

import '../styles/static-asset-tile.scss';

interface Props {
    item?: SelectorStaticAsset;
    onSelect?: (item: StaticAsset) => void;
}

const TemplateManagementSelectorStaticAssetTile = ({ item, onSelect }: Props) => {
    if (!item?.originalRecord) return null;
    if (!onSelect) return null;

    // Select the asset and make sure we notify the usage endpoint of this selection.
    const handleSelect = (item: SelectorStaticAsset) => {
        //TODO: Add usage logic here.

        onSelect(item.originalRecord as StaticAsset);
    };

    return (
        <div onClick={() => handleSelect(item)} className="template-management-selector-static-asset-tile ">
            <div className="template-management-selector-static-asset-tile__preview">
                {item.icon}
                <div className="template-management-selector-static-asset-tile__overlay">
                    <Button variant="contained" color="primary" className="template-management-selector-static-asset-tile__button">
                        {Translation.get('actions.select', 'common')}
                    </Button>
                </div>
            </div>
            <TemplateManagementAssetTileInfo
                title={item.title || ''}
                subtitle={Translation.get(`sub_types.${item.originalRecord.subType}`, 'template-management')}
                icon="upload"
                small
            />
        </div>
    );
};

export default TemplateManagementSelectorStaticAssetTile;
