import { CustomTemplateType, EmailTemplateType, TDTemplateType, TemplateType } from 'types/templates.type';
import { CREATIVE_TEMPLATE_TYPES, CUSTOM_TEMPLATE_TYPES, EMAIL_TEMPLATE_TYPES, TD_TEMPLATE_TYPES } from 'components/template-management/constants';
import { CustomTemplateAsset, TDTemplateAsset, TemplateAsset } from '../types/template-management.type';

/**
 * Checked if the Template type is a Template Designer type.
 * @param {string} asset - asset to check on either custom or td.
 * @returns {boolean} - If it is an Template Designer type.
 */
export const isTemplateDesignerTemplate = (asset: TemplateAsset): asset is TDTemplateAsset => {
    return asset !== null && typeof asset === 'object' && typeof asset.subType === 'string' && isTemplateDesignerTemplateType(asset.subType);
};

/**
 * Checked if the Template type is a customer type.
 * @param {string} asset - asset to check on either custom or td.
 * @returns {boolean} - If it is an Template Designer type.
 */
export const isCustomTemplate = (asset: TemplateAsset): asset is CustomTemplateAsset => {
    return asset !== null && typeof asset === 'object' && typeof asset.subType === 'string' && isCustomTemplateType(asset.subType);
};

/**
 * Checked if the Template type is a customer type.
 * @param {string} asset - asset to check on either custom or td.
 * @returns {boolean} - If it is an Template Designer type.
 */
export const isCreativeBuilderTemplate = (asset: TemplateAsset): boolean => {
    return asset !== null && typeof asset === 'object' && typeof asset.subType === 'string' && isCreativeBuilderTemplateType(asset.subType);
};

/**
 * @param type Type of template
 * @returns boolean if the template is a email template
 */
export const isEmailTemplateType = (type: TemplateType): type is EmailTemplateType => {
    return EMAIL_TEMPLATE_TYPES.includes(type);
};

/**
 * @param type Type of template
 * @returns boolean if the template is a template designer template
 */
export const isTemplateDesignerTemplateType = (type: TemplateType): type is TDTemplateType => {
    return TD_TEMPLATE_TYPES.includes(type);
};

/**
 * @param type
 * @returns boolean if the template is a custom template
 */
export const isCustomTemplateType = (type: TemplateType): type is CustomTemplateType => {
    return CUSTOM_TEMPLATE_TYPES.includes(type);
};

/**
 * @param type
 * @returns boolean if the template is a template that is comptible with the creative builder.
 */
export const isCreativeBuilderTemplateType = (type: TemplateType): boolean => {
    return CREATIVE_TEMPLATE_TYPES.includes(type);
};
