import React, { useState, useEffect, useCallback, useRef } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import Translation from 'components/data/Translation';
import TemplateManagementAssetTilePreviewImage from './preview-image';

import '../styles/preview-slideshow.scss';

interface Props {
    images: string[];
    start?: boolean;
}

const TemplateManagementAssetTilePreviewSlideshow = ({ images, start }: Props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        // Preload images
        images.forEach((url) => {
            const img = new Image();
            img.src = url;
        });
    }, [images]);

    const nextSlide = useCallback(() => {
        setActiveIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
        timeoutRef.current = setTimeout(nextSlide, 2000); // Change slide every 2 seconds
    }, [images.length]);

    const stopAnimation = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    }, []);

    useEffect(() => {
        if (start && !timeoutRef.current) {
            nextSlide(); // Start the animation
        }

        return () => {
            stopAnimation();
        };
    }, [start, nextSlide, stopAnimation]);

    const handleNext = (e) => {
        e.stopPropagation();
        stopAnimation();
        nextSlide();
    };

    return (
        <div className="template-management-asset-tile-preview-slideshow">
            <SwitchTransition mode="out-in">
                <CSSTransition key={activeIndex} nodeRef={containerRef} timeout={300} classNames="template-management-asset-tile-preview-slideshow__slide">
                    <div className="template-management-asset-tile-preview-slideshow__slide-content" ref={containerRef}>
                        <TemplateManagementAssetTilePreviewImage url={images[activeIndex]} />
                    </div>
                </CSSTransition>
            </SwitchTransition>
            <div
                onClick={handleNext}
                className="template-management-asset-tile-preview-slideshow__control">{`${activeIndex + 1} ${Translation.get('labels.of', 'common')} ${images.length}`}</div>
        </div>
    );
};

export default TemplateManagementAssetTilePreviewSlideshow;
