import React from 'react';
import Translation from 'components/data/Translation';
import { TemplateAsset } from 'components/template-management/types/template-management.type';
import Button from 'components/ui-components-v2/Button';
import { SelectorAsset } from 'components/asset-management/components/selector/types/selector-asset.type';
import { TemplateManagementAssetTileInfo, TemplateManagementAssetTilePreview } from '../../asset-tile';

import '../styles/tile.scss';

interface Props {
    item?: SelectorAsset;
    onSelect?: (item: TemplateAsset) => void;
}

const TemplateManagementSelectorTile = ({ item, onSelect }: Props) => {
    if (!item?.originalRecord) return null;
    if (!onSelect) return null;

    // Select the asset and make sure we notify the usage endpoint of this selection.
    const handleSelect = (item: SelectorAsset) => {
        //TODO: Add usage logic here.

        onSelect(item.originalRecord as TemplateAsset);
    };

    return (
        <div onClick={() => handleSelect(item)} className="template-management-selector-tile">
            <TemplateManagementAssetTilePreview template={item.originalRecord as TemplateAsset} className="template-management-selector-tile__preview">
                <div className="template-management-selector-tile__overlay">
                    <Button variant="contained" color="primary" className="template-management-selector-tile__button">
                        {Translation.get('actions.select', 'common')}
                    </Button>
                </div>
            </TemplateManagementAssetTilePreview>
            <TemplateManagementAssetTileInfo
                title={item.title || ''}
                subtitle={Translation.get(`sub_types.${item.originalRecord.subType}`, 'template-management')}
                small
            />
        </div>
    );
};

export default TemplateManagementSelectorTile;
