import {
    isCustomTemplate,
    isTemplateDesignerTemplate,
    isCreativeBuilderTemplate,
    isCustomTemplateType,
    isTemplateDesignerTemplateType,
    isCreativeBuilderTemplateType
} from './isTemplate';
import { getTemplatePreviewImage } from './getTemplatePreviewImage';
import { isAMV2Enabled } from './isAmV2Enabled';
import { getTemplateIdentifier } from './getTemplateIdentifier';

export {
    getTemplatePreviewImage,
    isAMV2Enabled,
    isTemplateDesignerTemplate,
    isCustomTemplate,
    isCreativeBuilderTemplate,
    isTemplateDesignerTemplateType,
    isCustomTemplateType,
    isCreativeBuilderTemplateType,
    getTemplateIdentifier
};
