import React from 'react';

import '../styles/preview-image.scss';

interface props {
    url: string;
}

const TemplateManagementAssetTilePreviewImage = ({ url }: props) => {
    return <img src={url} className="template-management-asset-tile-preview-image" />;
};

export default TemplateManagementAssetTilePreviewImage;
