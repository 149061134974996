import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import { TemplateAsset } from '../../../types/template-management.type';
import TemplateManagementAssetTilePreviewSwitch from './preview-switch';

import '../styles/preview.scss';

interface Props {
    children?: ReactElement | false;
    template: TemplateAsset;
    hovered?: boolean;
    selected?: boolean;
    className?: string;
}

const TemplateManagementAssetTilePreview = ({ template, hovered, selected, className, children }: Props) => {
    const [hoveredLocal, setHoveredLocal] = useState(false); // We watch for hover state locally in case there is no overlay blocking local hovering.
    return (
        <div
            onMouseEnter={() => setHoveredLocal(true)}
            onMouseLeave={() => setHoveredLocal(false)}
            className={classNames('template-management-asset-tile-preview', className, {
                'template-management-asset-tile-preview--selected': selected
            })}>
            <TemplateManagementAssetTilePreviewSwitch template={template} hovered={hoveredLocal || hovered} />
            {children && children}
        </div>
    );
};

export default TemplateManagementAssetTilePreview;
