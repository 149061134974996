import { AssetType } from 'types/asset.type';
import User from 'components/data/User';

/**
 * Function checks if amv2 is enabled based on rights of a user
 * @returns
 */
const isAMV2Enabled = (type: AssetType = 'template'): boolean => {
    switch (type) {
        case 'template':
            return User.hasRight('assetManagerTemplateManagement') || User.hasRight('assetManagerTemplateWrite') || User.hasRight('assetManagerTemplateRead');
        case 'creative':
            return User.hasRight('assetManagerCreativeManagement') || User.hasRight('assetManagerCreativeWrite') || User.hasRight('assetManagerCreativeRead');
        case 'media':
            return User.hasRight('assetManagerMediaManagement') || User.hasRight('assetManagerMediaWrite') || User.hasRight('assetManagerMediaRead');
        default:
            return false;
    }
};

export { isAMV2Enabled };
